import * as actionTypes from 'actions';

const initialState = {
  members: [],
  error: null,
  tags: [],
};

const rewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REWARD_GET: {
      console.log(action.tags)
      return {
        members: action.members,
        tags: action.tags,
        error: null
      };
    }

    case actionTypes.REWARD_FAIL: {
      return {
        ...state,
        error: action.message
      };
    }

    default: {
      return state;
    }
  }
};

export default rewardReducer;
