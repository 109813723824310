import * as actionTypes from 'actions';

const initialState = {
    banners: [],
    error: null,
    loading: false
};

const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BANNER_GET_ALL: {
            return {
                ...state,
                banners: action.banners,
                error: null,
                loading: false
            };
        }

        case actionTypes.BANNER_CREATE: {
            return {
                ...state,
                banners: [action.banner, ...state.banners],
                error: null,
                loading: false
            };
        }

        case actionTypes.BANNER_DELETE: {
            return {
                ...state,
                banners: state.banners.filter(banner => banner.id !== action.id),
                error: null,
                loading: false
            };
        }

        case actionTypes.BANNER_FAIL: {
            return {
                ...state,
                error: action.message,
                loading: false
            };
        }

        case actionTypes.BANNER_ERROR_CLEAR: {
            return {
                ...state,
                error: null
            };
        }

        case actionTypes.BANNER_LOADING: {
            return {
                ...state,
                loading: true
            };
        }

        default: {
            return state;
        }
    }
};

export default bannerReducer;