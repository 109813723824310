import React, { useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Snackbar
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { BANNER_ERROR_CLEAR, deleteBanner, getAllBanners } from 'actions';
import { BannerCreateModal } from './components';
import { Alert } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    width: '100%',
    height: 400
  },
  author: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(1)
  },
  tags: {
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const Banners = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const bannerStore = useSelector(state => state.banner);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(getAllBanners());
  }, []);

  const handleError = () => {
    dispatch({ type: BANNER_ERROR_CLEAR });
  };

  return (
    <React.Fragment>
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title="Banners"
        action={<Button color="primary" variant="outlined" onClick={() => setOpen(true)}>Add</Button>}
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          {bannerStore.loading ? (
            <div className={classes.inner}>
              <Skeleton height={90} animation="wave" />
              <Skeleton height={90} />
              <Skeleton height={90} animation="wave" />
            </div>
          ) : (
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sortDirection="desc">
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel active direction="desc">
                          Title
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bannerStore.banners.map((banner) => (
                    <TableRow hover key={banner.id}>
                      <TableCell>{banner.title}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => dispatch(deleteBanner(banner.id))}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </PerfectScrollbar>
      </CardContent>
    </Card>
    <BannerCreateModal open={open} onClose={() => setOpen(false)} />
    <Snackbar
    open={!!bannerStore.error}
    autoHideDuration={6000}
    onClose={handleError}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert variant="error" message={bannerStore.error} onClose={handleError} />
    </Snackbar>
    </React.Fragment>
  );
};

Banners.propTypes = {
  className: PropTypes.string
};

export default Banners;
