import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const all_tags = [
  { title: 'Locked', value: 'locked' },
  { title: 'Unlocked', value: 'unlocked' },
  { title: 'Redeemed', value: 'redeemed' },
];

const RewardFilter = props => {
  const { open, onClose, onFilter, className, ...rest } = props;

  const classes = useStyles();
  const rewardStore = useSelector(state => state.reward);

  const initialValues = {
    tags: rewardStore.tags
  };

  const [values, setValues] = useState({ ...initialValues });

  const handleClear = () => {
    setValues({ tags: [] });
    onFilter && onFilter({ tags: [] });
  };

  const handleFieldChange = (event, newValue) => {
    event.persist && event.persist();
    setValues(values => ({
      ...values,
      tags: [...newValue.map(p => p.value)]
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    onFilter && onFilter(values);
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary">
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}>
        <div className={classes.header}>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <Typography component="p" gutterBottom variant="overline">
                  Select Tags
                </Typography>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={all_tags}
                  getOptionLabel={option => option.title}
                  x
                  filterSelectedOptions
                  value={all_tags.filter(p => values.tags.includes(p.value))}
                  onChange={handleFieldChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={classes.field}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      label="filterSelectedOptions"
                      placeholder="Enter Tags"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button fullWidth onClick={handleClear} variant="contained">
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
          <Button color="primary" fullWidth type="submit" variant="contained">
            Apply filters
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

RewardFilter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default RewardFilter;
