import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'actions';
import { Redirect } from 'react-router';

const Logout = () => {
    const dispatch = useDispatch();
    dispatch(logout());

    return <Redirect to="/auth/login" />;
};

export default Logout;