import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import axios from 'utils/axios';
import getInitials from 'utils/getInitials';
import { Label, GenericMoreButton, MatrixFilter } from 'components';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    width: '100%',
    height: 400
  },
  author: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(1)
  },
  tags: {
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const LatestProjects = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [matrixs, setMatrixs] = useState([]);
  const [matrixParam, setMatrixParam] = useState('total');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    const fetchMatrixs = () => {
      setIsLoading(true);
      axios
        .get('/dashboard/admin/matrix', { params: { param: matrixParam } })
        .then(res => {
          if (mounted) setMatrixs(res.data);
        })
        .finally(() => setIsLoading(false));
    };

    fetchMatrixs();

    return () => {
      mounted = false;
    };
  }, [matrixParam]);

  const getTitle = () => {
    switch (matrixParam) {
      case "total":
        return "Total pools";
      case "this_month":
        return "This month pools";
      case "today":
        return "Today's pools";
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={<MatrixFilter filter={matrixParam} onChange={setMatrixParam} />}
        title={getTitle()}
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          {isLoading ? (
            <div className={classes.inner}>
              <Skeleton height={90} animation="wave" />
              <Skeleton height={90} />
              <Skeleton height={90} animation="wave" />
            </div>
          ) : (
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sortDirection="desc">
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel active direction="desc">
                          Name
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matrixs.map((matrix, idx) => (
                    <TableRow hover key={idx}>
                      <TableCell>{matrix.matrix}</TableCell>
                      <TableCell>{matrix.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="/management/members-pools"
          variant="text">
          See details
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestProjects.propTypes = {
  className: PropTypes.string
};

export default LatestProjects;
