import axios from '../utils/axios';

export const POOL_GET = 'POOL_GET';
export const POOL_FAIL = 'POOL_FAIL';

/**
 *
 * @param {number[]} pools
 * @returns
 */
export const getMemberPools = (pools = []) => dispatch =>
  axios
    .get('/accounts/user-pools', { params: { pools: pools.join(',') } })
    .then(res =>
      dispatch({
        type: POOL_GET,
        members: res.data,
        pools,
      })
    )
    .catch(error => dispatch({ type: POOL_FAIL, message: error.message }));
