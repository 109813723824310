import * as actionTypes from 'actions';

const initialState = {
  members: [],
  error: null,
  pools: [],
};

const poolReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POOL_GET: {
      console.log(action.pools)
      return {
        members: action.members,
        pools: action.pools,
        error: null
      };
    }

    case actionTypes.POOL_FAIL: {
      return {
        ...state,
        error: action.message
      };
    }

    default: {
      return state;
    }
  }
};

export default poolReducer;
