import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import axios from '../../utils/axios';
import { Snackbar } from '@material-ui/core';
import { Page, Alert } from 'components';
import {
  Header,
  LatestProjects,
  NewProjects,
  RealTime,
  RoiPerCustomer,
  TeamTasks,
  DetailsCard,
  SystemHealth,
  PerformanceOverTime,
  Banners,
} from './components';
import { FinancialStats } from 'views/DashboardAnalytics/components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const DashboardDefault = () => {
  const classes = useStyles();
  const [dash, setDash] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get('/dashboard/admin')
      .then(res => setDash(res.data))
      .catch(error => setError(error.message));
  }, []);

  const handleError = () => setError(false);

  return (
    <Page className={classes.root} title="Default Dashboard">
      <Header />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Total Members"
            value={dash ? dash.totalMembers : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Total Active Members"
            value={dash ? dash.activeMembers : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Universal Members"
            value={dash ? dash.autopoolMembers : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Total Income from Activation"
            value={dash ? dash.activationIncome : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Total Income from E-Pin"
            value={dash ? dash.epinIncome : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Pool Upgrade Income"
            value={dash ? dash.upgradeIncome : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Total Withdrawal"
            value={dash ? dash.totalWithdrawal : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Total Token Buyback"
            value={dash ? dash.totalBuyback : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Activation Commission"
            value={dash ? dash.activationCommission : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Rewards Amount"
            value={dash ? dash.rewards : 'Loading...'}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DetailsCard
            name="Today's Joining"
            value={dash ? dash.joinedToday : 'Loading...'}
          />
        </Grid>
        {/* <Grid
          item
          lg={3}
          xs={12}
        >
          <RealTime />
        </Grid> */}
        <Grid item lg={5} xl={8} xs={12}>
          <LatestProjects />
        </Grid>
        <Grid item lg={7} xl={9} xs={12}>
          <FinancialStats />
        </Grid>
        <Grid item lg={6} xs={12}>
          <PerformanceOverTime
            title="Activation"
            monthData={dash ? dash.activationWeekly : []}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <PerformanceOverTime
            title="Income"
            monthData={dash ? dash.incomeWeekly : []}
          />
        </Grid>
        <Grid item lg={7} xl={9} xs={12}>
          <PerformanceOverTime
            title="Withdrawal"
            monthData={dash ? dash.withdrawalWeekly : []}
          />
        </Grid>
        <Grid
          item
          lg={5} xl={8} xs={12}
        >
          <Banners />
        </Grid>
      </Grid>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert variant="error" message={error} onClose={handleError} />
      </Snackbar>
    </Page>
  );
};

export default DashboardDefault;
