import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import memberReducer from './memberReducer';
import epinReducer from './epinReducer';
import withdrawalReducer from './withdrawalReducer';
import poolReducer from './poolReducer';
import rewardReducer from './rewardReducer';
import bannerReducer from './bannerReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  member: memberReducer,
  pool: poolReducer,
  reward: rewardReducer,
  epin: epinReducer,
  withdrawal: withdrawalReducer,
  banner: bannerReducer
});

export default rootReducer;
