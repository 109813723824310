import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import axios from 'utils/axios';

import { GenericMoreButton } from 'components';
import { Chart } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    width: '100%'
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 400
  }
}));

const FinancialStats = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [data, setData] = useState({
    thisYear: [],
    lastYear: []
  });

  useEffect(() => {
    let mounted = true;

    const fetchMatrixs = () => {
      axios.get('/dashboard/admin/trade-stat').then(res => {
        const dataSet = res.data.data.map(d => d / 1000);
        if (mounted)
          setData({
            thisYear: dataSet,
            lastYear: dataSet.map(d => (d > 0.001 ? d - 0.001 : 0))
          });
      });
    };

    fetchMatrixs();

    return () => {
      mounted = false;
    };
  }, []);

  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={<GenericMoreButton />} title="Trade Stats" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart className={classes.chart} data={data} labels={labels} />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

FinancialStats.propTypes = {
  className: PropTypes.string
};

export default FinancialStats;
