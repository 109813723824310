import React, { Fragment, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AchiveIcon from '@material-ui/icons/ArchiveOutlined';

const useStyles = makeStyles(() => ({}));

const MatrixFilter = props => {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <Fragment>
      <Tooltip title="More options">
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem selected={props.filter === 'total'}>
          <ListItemText
            primary="Total"
            onClick={() => props.onChange('total')}
          />
        </MenuItem>
        <MenuItem selected={props.filter === 'this_month'}>
          <ListItemText
            primary="This Month"
            onClick={() => props.onChange('this_month')}
          />
        </MenuItem>
        <MenuItem selected={props.filter === 'today'}>
          <ListItemText
            primary="Today"
            onClick={() => props.onChange('today')}
          />
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

MatrixFilter.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(MatrixFilter);
