import * as actionTypes from 'actions';

const initialState = {
  members: [],
  filter: { status: 'all' },
  error: null
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MEMBER_GET: {
      return {
        members: action.members,
        filter: action.filter,
        error: null
      };
    }

    case actionTypes.MEMBER_FAIL: {
      return {
        ...state,
        error: action.message
      };
    }

    case actionTypes.MEMBER_ACTIVATE: {
      state.members.forEach(m => {
        if (m.id === action.id) {
          m.status = 'active';
          m.activatedAt = new Date();
          m.epinId = action.epin;
        }
      });
      return { ...state };
    }

    case actionTypes.MEMBER_CREDIT: {
      const newList = [...state.members];
      const member = state.newList.find(member => member.id === action.userId);
      if (member) {
        member.wallet = member.wallet + action.amount;
      }
      return {
        ...state,
        members: newList
      };
    }

    case actionTypes.MEMBER_DEBIT: {
      const newList = [...state.members];
      const member = state.newList.find(member => member.id === action.userId);
      if (member) {
        member.wallet = member.wallet - action.amount;
      }
      return {
        ...state,
        members: newList
      };
    }

    default: {
      return state;
    }
  }
};

export default memberReducer;
