import axios from '../utils/axios';

export const BANNER_GET_ALL = 'BANNER_GET_ALL';
export const BANNER_CREATE = 'BANNER_CREATE';
export const BANNER_DELETE = 'BANNER_DELETE';
export const BANNER_FAIL = 'BANNER_FAIL';
export const BANNER_LOADING = 'BANNER_LOADING';
export const BANNER_ERROR_CLEAR = 'BANNER_ERROR_CLEAR';

export const getAllBanners = () => dispatch => {
    dispatch({ type: BANNER_LOADING });
    return axios.get('/banner')
        .then(res => dispatch({ type: BANNER_GET_ALL, banners: res.data }))
        .catch(error => dispatch({ type: BANNER_FAIL, message: error.message }));
}

export const createBanner = (banner) => dispatch => {
    const formData = new FormData();
    formData.append('image', banner.image);
    formData.append('title', banner.title);
    formData.append('description', banner.description);
    formData.append('link', banner.link);
    
    dispatch({ type: BANNER_LOADING });
    return axios.post('/banner', formData)
        .then(res => dispatch({ type: BANNER_CREATE, banner: res.data }))
        .catch(error => dispatch({ type: BANNER_FAIL, message: error.message }));
}

export const deleteBanner = (id) => dispatch => {
    dispatch({ type: BANNER_LOADING });
    return axios.delete(`/banner/${encodeURIComponent(id)}`)
        .then(res => dispatch({ type: BANNER_DELETE, id }))
        .catch(error => dispatch({ type: BANNER_FAIL, message: error.message }));
}