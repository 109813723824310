import axios from '../utils/axios';

export const MEMBER_GET = 'MEMBER_GET';
export const MEMBER_CREDIT = 'MEMBER_CREDIT';
export const MEMBER_DEBIT = 'MEMBER_DEBIT';
export const MEMBER_SEND_EPINS = 'MEMBER_SEND_EPINS';
export const MEMBER_FAIL = 'MEMBER_FAIL';
export const MEMBER_ACTIVATE = 'MEMBER_ACTIVATE';

/**
 *
 * @param {'all' | 'active' | 'inactive'} status
 * @param {number[] | undefined} wallet
 */
export const getMembers = (status, wallet) => dispatch =>
  axios
    .get('/accounts/users', {
      params: { status, wallet: wallet ? wallet.join(',') : undefined }
    })
    .then(res =>
      dispatch({
        type: MEMBER_GET,
        filter: { status, wallet },
        members: res.data
      })
    )
    .catch(error => dispatch({ type: MEMBER_FAIL, message: error.message }));

/**
 *
 * @param {string} id
 */
export const activateMember = id => dispatch =>
  axios
    .put(`/accounts/activate/${encodeURIComponent(id)}`)
    .then(res => window.location.reload())
    // .then(res => dispatch({ type: MEMBER_ACTIVATE, id, epin: res.data }))
    .catch(error => dispatch({ type: MEMBER_FAIL, message: error.message }));

/**
 *
 * @param {string} userId
 * @param {number} amount
 */
export const creditMember = (userId, amount) => dispatch =>
  axios
    .put('/accounts/credit-wallet', { userId, amount })
    .then(res => dispatch({ type: MEMBER_CREDIT, userId, amount }))
    .catch(error => dispatch({ type: MEMBER_FAIL, message: error.message }));

/**
 *
 * @param {string} userId
 * @param {number} amount
 */
export const debitMember = (userId, amount) => dispatch =>
  axios
    .put('/accounts/debit-wallet', { userId, amount })
    .then(res => dispatch({ type: MEMBER_DEBIT, userId, amount }))
    .catch(error => dispatch({ type: MEMBER_FAIL, message: error.message }));

/**
 *
 * @param {string} sendTo
 * @param {number} total
 */
export const sendEpins = (sendTo, total) => dispatch =>
  axios.post('/user-epin/send', { sendTo, total });
