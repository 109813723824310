import axios from '../utils/axios';

export const REWARD_GET = 'REWARD_GET';
export const REWARD_FAIL = 'REWARD_FAIL';

/**
 *
 * @param {string[]} tags
 * @returns
 */
export const getAllRewards = (tags = []) => dispatch =>
  axios
    .get('/rewards/all', { params: { tags: tags.join(',') } })
    .then(res =>
      dispatch({
        type: REWARD_GET,
        members: res.data,
        tags
      })
    )
    .catch(error => dispatch({ type: REWARD_FAIL, message: error.message }));
