import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Button,
  colors
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createBanner } from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const BannerCreateModal = props => {
  const { open, onClose, className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    title: '',
    image: null,
    description: '',
    link: '',
  });

  if (!open) {
    return null;
  }

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'file'
          ? event.target.files[0]
          : event.target.value
    }));
  };

  const onSave = () => {
    const state = { ...formState }
    dispatch(createBanner(state));
    onClose();
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Add Banner
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
            <TextField
              fullWidth
              label="Title"
              name="title"
              onChange={handleFieldChange}
              value={formState.title}
              variant="outlined"
            />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
           <input type="file" name='image' onChange={handleFieldChange} />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
            <TextField
              fullWidth
              label="Description"
              name="description"
              onChange={handleFieldChange}
              value={formState.description}
              variant="outlined"
            />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
            <TextField
              fullWidth
              label="Link"
              name="link"
              onChange={handleFieldChange}
              value={formState.link}
              variant="outlined"
            />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Close
            </Button>
            <Button
              className={classes.saveButton}
              onClick={onSave}
              variant="contained"
            >
              Save
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

BannerCreateModal.displayName = 'Create Banner';

BannerCreateModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

BannerCreateModal.defaultProps = {
  open: false,
  onClose: () => { }
};

export default BannerCreateModal;
